
import { Table, TableColumn } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import VueTimepicker from "vue2-timepicker";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import ConceptsApplyAllModal from './ConceptsApplyAllModal';
import moment from "moment";

interface OpeningHoursDataObject {
  day: string;
  open: string;
  close: string;
}

interface TimeDataOject {
  time: string;
  updatedTime: string;
}

interface HourTimeObject {
  day: string;
  open: string;
  closed: string;
}

const ConceptOpeningHoursProps = Vue.extend({
  props: {
    openingHours: {
      type: Array,
      default: () => [],
    },
  },
});

@Component({
  components: {
    VueTimepicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ConceptOpeningHoursScheduleTime: () =>
      import("./ConceptsOpeningHoursScheduleTime.vue"),
    Translations,
    ConceptsApplyAllModal,
  },
  mixins: [translations],
})
export default class ConceptOpeningHours extends ConceptOpeningHoursProps {
  translate!: Function;
  public openModal: boolean = false;
  public disable: boolean = true;
  public flag: boolean = false;
  days: Array<String> = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  @Watch('openingHours', { immediate: true, deep: true })
  onOpeningHoursChanged(hours: any) {
    hours.map(
      (item: any) => {
        item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
        item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

        return item;
      }
    );
  }

  mounted() {
    console.log('openingHours: ', this.openingHours);
  }

  public handleClose(value: any): void {
    console.log('handleClose: ', value);
    this.openModal = value;
  }

  public onApply(): void {
    this.openModal = true;
  }

  getDay(dayInNumber: string) {
    return this.days[+dayInNumber];
  }

  openOpeningHours(i: any, o: any) {

    if(i != o) {
        this.flag = true;
        return false;
    }
        this.flag = false;
  }

  checkOpeninghours(dis: any) {
    if(dis != this.disable) {
      return 'pointer-events: auto';
    }

    return 'pointer-events: none;background-color: #ddd; border: 1px solid #ddd;'
  }
}
